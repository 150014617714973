import Swal from 'sweetalert2';

export const checkResponseMessage = async (isSuccess, title, text, isConfimation) => {
    if( isSuccess === true ){
        Swal.fire({
            title: title,
            text: text,
            icon: 'success',
        })
        return true
    }else if(isConfimation){
        const result = await Swal.fire({
            title,
            text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        });
        if (result.isConfirmed) {
            return true; // Resolve with confirmation
        } else {
            return false; // Resolve with cancellation
        }
    }else{
        if( text === "TokenExpiredError" ){
            localStorage.clear()
            Swal.fire({
                title: "Token Expired",
                text: "Your login session Expired, please relogin",
                icon: 'error',
                confirmButtonColor: '#1b4460',
            })
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }else{
            Swal.fire({
                title: title,
                text: text,
                icon: 'error',
                confirmButtonColor: '#1b4460',
            })
        }
        return false; // Resolve with failure
    }
  }