const initialState = {
  propertyResp:null,
  changePropertyStatusResp:null,
  allAvailRoomResp: null,
  createPropertyResp: null,
  propertyDetailResp: null,
  propertyDeleteResp: null,
  propertyDetailUpdateResp: null,
}
  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_PROPERTY': {
      return {...state, propertyResp: action.payload}
    }
    case 'SET_ALL_AVAIL_ROOM': {
      return {...state, allAvailRoomResp: action.payload}
    }
    case 'SET_PROPERTY_DETAIL_RESP':{
      return {...state, propertyDetailResp: action.payload}
    }
    case 'SET_CREATE_PROPERTY': {
      return {...state, createPropertyResp: action.payload.data}
    }
    case 'SET_DELETE_PROPERTY_RESP':{
      return {...state, propertyDeleteResp: action}
    }
    case 'SET_CHANGE_PROPERTY_STATUS': {
      return {...state, changePropertyStatusResp: action.payload}
    }
    case 'SET_TRACK_SHIPMENT': {
      return {...state, trackShipmentResp: action.payload}
    }
    case 'SET_UPDATE_PROPERTY_RESP':{
      return {...state, propertyDetailUpdateResp: action.payload}
    }
    default:
      return state
  }
}

export default reducer
