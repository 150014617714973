import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { connect } from "react-redux";
import { setCreateAdmin, setAllProvince, setAllCity } from '../store/actions/adminAction'
import { checkResponseMessage } from "../helper/helper";
import { useNavigate } from 'react-router-dom';

const AddAdminPage = ({ dispatch, dataAdmin }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [availProvince, setAvailProvince] = useState([]);
  const [availCity, setAvailCity] = useState([]);

  const doCreateAdmin = (e) => {
    e.preventDefault()
    if(email == "" || hotelName == "" || address == "" || phone == "" || password == "" || confirmPassword == "" || selectedProvince == "" || selectedCity == ""){
      checkResponseMessage(false, "Error", "Mohon isi semua data")
    }else if( password !== confirmPassword){
      checkResponseMessage(false, "Error", "Password dan konfirmasi Password harus sama")
    }else{
      const dataCreate = {
        email,
        hotel_name: hotelName,
        address,
        phone,
        password,
        confirm_password: confirmPassword,
        province: selectedProvince.name,
        city: selectedCity.name,
      }
      setCreateAdmin(dispatch, dataCreate)
    }
  }
  
  const handleSelect = (e, type) => {
    const splitValue = e.target.value.split("||")
    if( type === "Provinsi" ){
      setSelectedProvince({id: splitValue[0], name: splitValue[1]}) 
    }else{
      setSelectedCity({id: splitValue[0], name: splitValue[1]}) 
    }
  }

  const adjustDataFormat = (data, section) => {
    if( section === "province"){
      setAvailProvince(data)
    }else{
      setAvailCity(data)
    }
  }

  useEffect(()=>{
    if( dataAdmin.createAdminResp ){
      setIsLoading(false)
      navigate('/adminManagement');
    }
  },[dataAdmin.createAdminResp])

  useEffect(()=>{
    if(selectedProvince.id){
      setAllCity(dispatch, selectedProvince.id)
    }
  },[selectedProvince])

  useEffect(()=>{
    if( dataAdmin.allProvinceResp ){
      let data = dataAdmin.allProvinceResp
      adjustDataFormat(data, "province")
      setAllCity(dispatch, data[0].id)
    }
  },[dataAdmin.allProvinceResp])

  useEffect(()=>{
    if( dataAdmin.allCityResp ){
      let data = dataAdmin.allCityResp
      adjustDataFormat(data, "city")
      setIsLoading(false)
    }
  },[dataAdmin.allCityResp])


  useEffect(()=>{
    setAllProvince(dispatch)
  },[])

  const dataForm = [
    {
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Password",
      type: "password",
      placeholder: "Masukkan Password",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: password,
      showPassword: false,
      action: setPassword,
    },{
      label: "Konfirmasi Password",
      type: "password",
      placeholder: "Masukkan Konfirmasi Password",
      spaceMd: "6",
      spaceXs: "12",
      value: confirmPassword,
      action: setConfirmPassword,
      required: true,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama Hotel",
      type: "text",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: hotelName,
      action: setHotelName,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Alamat Hotel",
      type: "text",
      placeholder: "Masukkan Alamat Hotel",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Provinsi",
      section: "Provinsi",
      type: "dropdown",
      placeholder: "Masukkan provinsi",
      spaceMd: "6",
      spaceXs: "6",
      value: selectedProvince,
      action: handleSelect,
      required: true,
      dataDropdown:availProvince
    },{
      label: "Kota",
      section: "Kota",
      type: "dropdown",
      placeholder: "Masukkan kota",
      spaceMd: "6",
      spaceXs: "6",
      value: selectedCity,
      action: handleSelect,
      required: true,
      dataDropdown: availCity,
    },{
    //   label: "Pilih Provinsi",
    //   type: "dropdown",
    //   section: "Provinsi",
    //   placeholder: "Pilih Provinsi",
    //   spaceMd: "6",
    //   spaceXs: "6",
    //   value: province,
    //   action: handleSelect,
    //   required: true,
    //   dataDropdown:[{id: 1, name:"male"},{id: 2, name:"female"}]
    // },{
    //   label: "Pilih Kota",
    //   type: "dropdown",
    //   section: "Kota",
    //   placeholder: "Pilih Kota",
    //   spaceMd: "6",
    //   spaceXs: "6",
    //   value: city,
    //   action: handleSelect,
    //   required: true,
    //   dataDropdown:[{id: 1, name:"male"},{id: 2, name:"female"}]
    // },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreateAdmin,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../adminManagement',
      spaceXs: "3",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Tambah Hotel Admin"}
        dataForm={dataForm}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        email={email}
        onSubmit={doCreateAdmin}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataAdmin: state.admin,
  };
};

export default connect(
  storage
)(AddAdminPage)
