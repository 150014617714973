import React, { useEffect } from "react";
import PropertyTable from '../components/Table/PropertyTable'


const PropertyPage = () => {
  useEffect(()=>{
    localStorage.setItem("pagePos","PropertyDetail")
  },[])

  return (    
    <div className="container_right_form">
      <PropertyTable 
        pageName={"Property Detail"}
        linkAddNew={""}
      />
    </div>
  );
};

export default PropertyPage;
