const initialState = {
  allAdminResp: null,
  adminDetailResp: null,
  adminMyselfDetailResp: null,
  adminDetailUpdateResp: null,
  createAdminResp: null,
  allProvinceResp: null,
  allCityResp: null,
  adminPassResp: null,
  adminDeleteResp: null,
  adminSelfUpdatePassResp: null,
  adminSelfUpdateDetailResp: null,
}
  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_ADMIN': {
      return {...state, allAdminResp: action.payload}
    }
    case 'SET_CREATE_ADMIN': {
      return {...state, createAdminResp: action.payload.user}
    }
    case 'SET_ADMIN_DETAIL_RESP':{
      return {...state, adminDetailResp: action.payload.data}
    }
    case 'SET_SELF_UPDATE_ADMIN_RESP':{
      return {...state, adminSelfUpdateDetailResp: action.payload.data}
    }
    case 'SET_ADMIN_MYSELF_RESP':{
      return {...state, adminMyselfDetailResp: action.payload.data}
    }
    case 'SET_UPDATE_PASS_ADMIN_RESP':{
      return {...state, adminPassResp: action.payload.data}
    }
    case 'SET_SELF_UPDATE_PASS_ADMIN_RESP':{
      return {...state, adminSelfUpdatePassResp: action.payload.data}
    }
    case 'SET_DELETE_ADMIN_RESP':{
      return {...state, adminDeleteResp: action}
    }
    case 'SET_UPDATE_ADMIN_RESP':{
      return {...state, adminDetailUpdateResp: action.payload}
    }
    case 'RESET_CREATE_ADMIN':{
      return {...state, createAdminResp: null}
    }
    case 'SET_ALL_PROVINCE': {
      return {...state, allProvinceResp: action.payload}
    }
    case 'SET_ALL_CITY': {
      return {...state, allCityResp: action.payload}
    }
    default:
      return state
  }
}

export default reducer
