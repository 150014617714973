import axios from 'axios';
import { checkResponseMessage } from '../../helper/helper'

export const setAllHotel= async (dispatch, page, paramFilter) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : paramFilter,
    url     : `${process.env.REACT_APP_API_URI}/hotel?offset=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_HOTEL', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}