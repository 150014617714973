import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { checkResponseMessage } from "../helper/helper";
import Swal from 'sweetalert2';
import { setAdminUpdateMySelf, setAllProvince, setAllCity, setAdminPassMySelf, setAdminMySelf } from '../store/actions/adminAction'

const AccountPage = ({ dispatch, dataAdmin }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { adminId } = useParams()
  const [id, setId] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confNewPassword, setConfNewPassword] = useState("");

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [availProvince, setAvailProvince] = useState([]);
  const [availCity, setAvailCity] = useState([]);

  const doUpdate = (e) => {
    e.preventDefault()
    const dataUpdate = {
      hotel_name: hotelName,
      address,
      phone,
      province: selectedProvince.name,
      city: selectedCity.name,
    }
    setAdminUpdateMySelf(dispatch, dataUpdate)
  }

  const doChangePassUpdate = (e) => {
    e.preventDefault()
    if( newPassword !== confNewPassword ){
      checkResponseMessage(false, "Error", "Password Baru dan konfirmasi Password harus sama")
    }else{
      const dataChangePass = {
        old_password: oldPassword,
        confirm_password: confNewPassword,
        password: newPassword,
      }
      setAdminPassMySelf(dispatch, dataChangePass)
    }
  }
  
  const handleSelect = (e, type) => {
    const splitValue = e.target.value.split("||")
    if( type === "Provinsi" ){
      setSelectedProvince({id: splitValue[0], name: splitValue[1]}) 
    }else{
      setSelectedCity({id: splitValue[0], name: splitValue[1]}) 
    }
  }

  const adjustDataFormat = (data, section) => {
    if( section === "province"){
      setAvailProvince(data)
    }else{
      setAvailCity(data)
    }
  }

  useEffect(()=>{
    if( dataAdmin.allProvinceResp ){
      let data = dataAdmin.allProvinceResp
      adjustDataFormat(data, "province")
      
      setAllCity(dispatch, data[0].id)
      setIsLoading(false)
    }
  },[dataAdmin.allProvinceResp])

  useEffect(()=>{
    if( dataAdmin.allCityResp ){
      let data = dataAdmin.allCityResp
      adjustDataFormat(data, "city")
      setIsLoading(false)
    }
  },[dataAdmin.allCityResp])

  useEffect(()=>{
    if(selectedProvince.id){
      setAllCity(dispatch, selectedProvince.id)
    }
  },[selectedProvince])

  useEffect(()=>{
    if( dataAdmin.adminPassResp ){
      Swal.fire({
        title: 'Success',
        text: 'Success Change Password',
        icon: 'Success',
        confirmButtonColor: '#1b4460',
      })
    }
  },[dataAdmin.adminPassResp])

  useEffect(()=>{
    setAllProvince(dispatch)
    // setAllCity(dispatch)
    setAdminMySelf(dispatch)
  },[dispatch])
  
  useEffect(()=>{
    if( dataAdmin.adminMyselfDetailResp ){
      let data = dataAdmin.adminMyselfDetailResp
      setId(data.id)
      setEmail(data.email)
      if(localStorage.getItem('is_super_admin') === 'false'){
        setHotelName(data.hotel.name)
        setAddress(data.hotel.address)
        setPhone(data.hotel.phone)
        setSelectedProvince({id: data.hotel.province_id, name: data.hotel.province}) 
        setSelectedCity({id: data.hotel.city_id, name: data.hotel.city}) 
      }
      setIsLoading(false)
    }
  },[dataAdmin.adminMyselfDetailResp])

  const dataForm = [
    {
      label: "Email (tidak dapat diganti)",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
      notEditable: true,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Password Lama",
      type: "password",
      placeholder: "Masukkan Password Lama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: oldPassword,
      showPassword: false,
      action: setOldPassword,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Password Baru",
      type: "password",
      placeholder: "Masukkan Password Baru",
      spaceMd: "6",
      spaceXs: "12",
      value: newPassword,
      action: setNewPassword,
      required: true,
    },{
      label: "Konfirmasi Password Baru",
      type: "password",
      placeholder: "Masukkan Lagi Password Baru",
      spaceMd: "6",
      spaceXs: "12",
      value: confNewPassword,
      action: setConfNewPassword,
      required: true,
    },{
      label: "Ganti Password",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doChangePassUpdate,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama Hotel",
      type: "text",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: hotelName,
      action: setHotelName,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Alamat Hotel",
      type: "text",
      placeholder: "Masukkan Alamat Hotel",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Pilih Provinsi",
      section: "Provinsi",
      type: "dropdown",
      section: "Provinsi",
      placeholder: "Pilih Provinsi",
      spaceMd: "6",
      spaceXs: "6",
      value: selectedProvince,
      action: handleSelect,
      required: true,
      dataDropdown:availProvince
    },{
      label: "Pilih Kota",
      section: "Kota",
      type: "dropdown",
      section: "Kota",
      placeholder: "Pilih Kota",
      spaceMd: "6",
      spaceXs: "6",
      value: selectedCity,
      action: handleSelect,
      required: true,
      dataDropdown: availCity,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../',
      spaceXs: "3",
    },
  ]

  const dataFormSuperAdmin = [
    {
      label: "Email (tidak dapat diganti)",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
      notEditable: true,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Password Lama",
      type: "password",
      placeholder: "Masukkan Password Lama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: oldPassword,
      showPassword: false,
      action: setOldPassword,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Password Baru",
      type: "password",
      placeholder: "Masukkan Password Baru",
      spaceMd: "6",
      spaceXs: "12",
      value: newPassword,
      action: setNewPassword,
      required: true,
    },{
      label: "Konfirmasi Password Baru",
      type: "password",
      placeholder: "Masukkan Lagi Password Baru",
      spaceMd: "6",
      spaceXs: "12",
      value: confNewPassword,
      action: setConfNewPassword,
      required: true,
    },{
      label: "Ganti Password",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doChangePassUpdate,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Account Detail"}
        dataForm={localStorage.getItem('is_super_admin') === 'true' ? dataFormSuperAdmin : dataForm}
        linkAccReview={"../accountReview"}
        id={id}
        pageFor={"detail"}
        email={email}
        onSubmit={doUpdate}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataAdmin: state.admin,
  };
};

export default connect(
  storage
)(AccountPage)
