import React, { useState } from "react";
import { Row, Col, Form  } from 'react-bootstrap'
import styles from './LoginRegister.module.scss'

const AboutHms = () => {

	return (
		<Col className={"col-md-12 mt-5 bg-grey"}>
      <br/>
      <h2>
        About HMS
      </h2>
      <p className={styles.privPol}>
        Hotel Manajemen Sistem (HMS) adalah perangkat lunak atau sistem digital yang dirancang untuk membantu pengelolaan
        operasional hotel secara efisien. Sistem ini biasanya mencakup berbagai fungsi seperti:

        <br/>
        <br/>
        1. Reservasi Kamar: Mengelola pemesanan kamar, baik online maupun offline.
        <br/>
        2. Check-in/Check-out: Mempermudah proses masuk dan keluar tamu.
        <br/>
        3. Pelaporan dan Analitik: Memberikan data analisis terkait kinerja hotel, termasuk tingkat hunian, pendapatan, dan ulasan tamu.
        <br/>
        4. Sistem ini membantu hotel untuk mengoptimalkan layanan, meningkatkan efisiensi operasional, dan memudahkan proses manajemen secara keseluruhan.
      </p>
		</Col>
	);
};

export default AboutHms;
