import React, { useState } from "react";
import { Row, Col, Form  } from 'react-bootstrap'
import styles from './LoginRegister.module.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';


const BasicKnowledge = () => {

	return (
		<Col className={"col-md-12 mt-5 bg-grey"}>
      <br/>
      <h2>
        Basic Knowledge
      </h2>
      <p className={styles.privPol}>
        
      <Accordion allowZeroExpanded>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                      Dashboard
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Dashboard : menampilkan data overview hotel anda secara general dalam harian, bulanan, dan tahunan untuk beberapa kategori dibawah ini :
                    <ul>
                      <li>
                        - total booking 
                      </li>
                      <li>
                        - total cancel booking
                      </li>
                      <li>
                        - revenue
                      </li>
                      <li>
                        - average room rate 
                      </li>
                      <li>
                        - melihay grafik statistik hotel
                      </li>
                      <li>
                        Khusus user super admin akan dapat memilih semua hotel yang terdaftar pada sistem. Serta melihat laporan per wilayah. 
                      </li>
                    </ul>
                  </p>
              </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                      Admin Management (Super Admnin)
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Admin management  (super admin) : menampilkan semua hotel terdaftar. Fitur tersedia: 
                    <ul>
                      <li>
                        melihat daftar admin hotel 
                      </li>
                      <li>
                        mendaftarkan admin hotel baru, 
                      </li>
                      <li>
                        melihat detail admin hotel 
                      </li>
                      <li>
                        melakukan update admin hotel 
                      </li>
                      <li>
                        menghapus admin hotel 
                      </li>
                      <li>
                        melakukan perubahan password admin hotel
                      </li>
                    </ul>
                  </p>
              </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                      Property Details 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Property Details : menu untuk menampilkan daftar tipe kamar. Fitur tersedia: 
                    <ul>
                      <li>
                        melihat daftar tipe kamar 
                      </li>
                      <li>
                        menambah tipe kamar baru
                      </li>
                      <li>
                        melihat detail kamar 
                      </li>
                      <li>
                        melakukan perubahan tipe kamar 
                      </li>
                      <li>
                        melihat semua kamar terdaftar pada semua hotel (super admin)
                      </li>
                      <li>
                        menambah kamar baru di semua hotel (super admin)
                      </li>
                    </ul>
                  </p>
              </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                    Reservation
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    Reservation: menu untuk menampilkan daftar resevasi. Fitur tersedia: 
                    <ul>
                      <li>
                        melihat daftar reservasi 
                      </li>
                      <li>
                        melakukan pengecekan ketersediaan kamar
                      </li>
                      <li>
                        membuat reservasi baru 
                      </li>
                      <li>
                        melihat detail reservasi 
                      </li>
                      <li>
                        mengganti detail reservasi
                      </li>
                      <li>
                        mengganti status reservasi 
                      </li>
                      <li>
                        membuat reservasi baru di semua hotel (super admin)
                      </li>
                      <li>
                        mengganti detail reservasi di semua hotel (super admin)
                      </li>
                      <li>
                        mengganti status resevasi di semua hotel (super admin)
                      </li>
                    </ul>
                  </p>
              </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                    User management / profile 
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p>
                    User management / profile :  menampilkan data admin yang sedang login. Memiliki fitur :
                    <ul>
                      <li>
                        melakukan update info admin hotel 
                      </li>
                      <li>
                        melakukan update password
                      </li>
                    </ul>
                    <br/>
                    <br/>
                  </p>
              </AccordionItemPanel>
          </AccordionItem>
      </Accordion>
        <br/>

        <br/>

        <br/>
        <br/>

        <br/>
        <br/>

        <br/>
        <br/>
      </p>
		</Col>
	);
};

export default BasicKnowledge;
