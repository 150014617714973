import React, { useEffect } from "react";
import ReportingTable from '../components/Table/ReportingTable'

const ReportingPage = () => {
  useEffect(()=>{
    localStorage.setItem("pagePos","ReportingPage")
  },[])

  return (    
    <div className="container_right_form">
      <ReportingTable
        pageName={"Reporting Page"}
      />
    </div>
  );
};

export default ReportingPage;
