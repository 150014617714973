import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom'
import { Row } from 'react-bootstrap'
import { toRupiah } from 'to-rupiah'
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

const InvoicePage = () => {
  const [datas, setDatas] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 0,
    },
    logo: {
      width: 120,
      height: 40,
    },
    titleContainer: {
      textAlign: 'center',
      flexGrow: 1,
      width: '90%',
    },
    title: {
      textAlign: 'center',
      width: '100%',
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: '10',
    },
    italic: {
      fontStyle: 'italic',
      fontSize: 8,
    },
    subHeader: {
      fontSize: 10,
      textAlign: 'center',
      marginBottom: 20,
    },
    subHeaderHotel: {
      fontSize: 10,
      textDecoration: "underline",
      textAlign: 'center',
      marginBottom: 20,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableRowLong: {
      flexDirection: 'row',
      width: "142.8%",
    },
    tableColHeader: {
      width: '20%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      backgroundColor: '#f0f0f0',
      padding: 5,
    },
    tableColHeader2: {
      width: '17.5%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      backgroundColor: '#f0f0f0',
      padding: 5,
    },
    tableCol2: {
      width: '17.5%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableColHeaderLong: {
      width: '35%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      backgroundColor: '#f0f0f0',
      padding: 5,
    },
    tableColLong: {
      width: '35%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableColHeader3: {
      width: '70',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      backgroundColor: '#f0f0f0',
      padding: 5,
    },
    tableCol3: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableCol4: {
      width: '70%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableCol5: {
      width: '30%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableCol: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableColFull: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableCellHeader: {
      fontSize: 10,
      fontWeight: 'bold',
    },
    tableCellHeader2: {
      fontSize: 8,
      fontWeight: 'bold',
    },
    tableCell: {
      fontSize: 10,
    },
    checkboxField: {
      marginLeft: 5,
      fontSize: 10,
    },
    signatureRow: {
      marginTop: 2,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    signatureField: {
      width: '45%',
      fontSize: '9pt',
      textAlign: 'center',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      paddingBottom: 60,
    },
    footer: {
      marginTop: 30,
      textAlign: 'center',
      fontSize: 10,
    },
  });
  
  // Sample data for the table
  const reservationDetails = {
    checkIn: '2024-11-01',
    checkOut: '2024-11-05',
    dailyRate: toRupiah(1000000),
    roomNumber: '101',
    numberOfGuests: 2,
    name: 'John Doe',
    address: 'Jl. Sudirman No. 123',
    city: 'Jakarta',
    mobilePhone: '08123456789',
    postalCode: '12345',
    email: 'john.doe@example.com',
    country: 'Indonesia',
    idCard: 'KTP 1234567890',
    birthDate: '1985-05-15',
    deposit: toRupiah(500000),
    paymentMethod: 'Tunai',
    receipt: 'Tanda Terima #123',
  };

  useEffect(()=>{
    setIsLoading(true)
    const datasss = JSON.parse( localStorage.getItem("dataBooking") ) 
    console.log("datas", datasss,localStorage.getItem("dataBooking"))
    setDatas(JSON.parse( localStorage.getItem("dataBooking") ) );
    setIsLoading(false)
  },[])

  const MyDocument = ({datas}) => {
    return(
    <>
      <Document>
        <Page style={styles.page}>
          {/* Header */}
          <Image style={styles.logo} src="/images/namhotellogo2.png" />
          <View style={styles.header}>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>
                PENDAFTARAN
                {'\n'} 
                GUEST REGISTRATION
              </Text>
            </View>
          </View>

          {/* Sub-header */}
          <Text style={styles.subHeader}>
            MOHON DIPERHATIKAN BAHWA BATAS WAKTU CHECK OUT ADALAH JAM 12:00 SIANG, WAKTU CHECK IN ADALAH JAM 14:00 SIANG TERGANTUNG KETERSEDIAAN KAMAR
            {'\n'}PLEASE NOTE THAT THE CHECK-OUT TIME IS 12:00 PM, AND CHECK-IN TIME IS 2:00 PM SUBJECT TO ROOM AVAILABILITY
          </Text>
          <View style={styles.tableRowLong}>
            <View style={styles.tableColHeaderLong}>
              <Text style={styles.tableCellHeader}>Hotel Name</Text>
            </View>
            <View style={styles.tableColLong}>
              <Text style={styles.tableCell}>{datas.hotel_name}</Text>
            </View>
          </View>
          
          {/* Table Header */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Check In</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Check Out</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Tipe Kamar</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Room Number</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>No. of Guests</Text>
              </View>
            </View>

            {/* Table Values */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{datas.start_date}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{datas.end_date}</Text>
              </View>
              <View style={styles.tableCol}>
                {/* <Text style={styles.tableCell}>{toRupiah(datas.dailyRate)}</Text> */}
                <Text style={styles.tableCell}>{datas?.property?.type}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{datas.room_number}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{datas.guests}</Text>
              </View>
            </View>

            {/* Name */}
            <View style={styles.tableRowLong}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Name</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.guest_name}</Text>
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Mobile Phone</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.phone}</Text>
              </View>
            </View>

            {/* City & Mobile Phone */}
            <View style={styles.tableRowLong}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Kota</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.city}</Text>
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Alamat</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.address}</Text>
              </View>
            </View>

            {/* Postal Code & Email */}
            <View style={styles.tableRowLong}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Kode Pos</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{}</Text>
                {/* <Text style={styles.tableCell}>{datas.postalCode}</Text> */}
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Email</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.email}</Text>
              </View>
            </View>

            {/* Country & ID Card */}
            <View style={styles.tableRowLong}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Negara</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.country}</Text>
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Passport / KTP</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.id_card}</Text>
              </View>
            </View>

            {/* Birth Date & Deposit */}
            <View style={styles.tableRowLong}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Tanggal Lahir</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.birth_date}</Text>
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Uang Muka / Deposit</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{}</Text>
                {/* <Text style={styles.tableCell}>{datas.deposit}</Text> */}
              </View>
            </View>

            {/* Payment Method */}
            <View style={styles.tableRowLong}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Cara Pembayaran</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.payment_method}</Text>
                {/* <Text style={styles.checkboxField}> __ Tunai</Text>
                <Text style={styles.checkboxField}> __ Kartu kredit</Text>
                <Text style={styles.checkboxField}> __ Charge to company</Text> */}
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Booking Source</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.booking_source}</Text>
              </View>
            </View>

            {/* Total Price */}
            <View style={styles.tableRowLong}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Total Pembayaran</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{toRupiah(datas.price)}</Text>
              </View>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader}>Tanda Terima</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{datas.receipt}</Text>
              </View>
            </View>
          </View>

          {/* Additional Text Before Signature */}
          <Text style={{ textAlign: 'center', fontSize: 10, marginBottom: 10 }}>
            Pembayaran secara Tunai atau Kartu Kredit harus disertakan dengan formulir ini untuk menutupi biaya insidentil yang	
            {'\n'} terjadi selama saya tinggal di hotel								
            {'\n'} Deposit by Cash or Credit Card must be included with this form to cover incidentals fee which is incurred tome 
            during my stay in this hotel								
          </Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>
                  <Text style={styles.tableCellHeader2}>
                    Hotel Tidak bertanggung jawab atas barang-barang berharga yang ditinggal di
                    dalam kamar. Safety Deposit Box tersedia di kamar secara Cuma-Cuma
                  </Text>
                  <Text style={styles.italic}>
                    {'\n'} 
                    The Hotel will not be responsible for any valueables left by the guest in the rooms.
                    Safety Deposit Box are available free of charge in the room
                    {'\n'} 
                    {'\n'} 
                  </Text>

                  <Text style={styles.tableCellHeader2}>
                    Saya setuju melakukan pembayaran di muka sebanyak dua kali harga kamar per				
                    malam dan menyelesaikan atas biaya yang terjadi selama saya tinggal di hotel dan				
                    akan menyelesaikan apabila biaya itu sudah mencapai Rp. 2.500.000 kecuali ada				
                    kesepakatan yang dibuat bersama sebelumnya.				
                  </Text>
                  <Text style={styles.italic}>
                    {'\n'} 
                    I agree to pay all charges incurred by me during my stay in the hotel and settle my account				
                    once it amounts to IDR 2,500,000, unless prior arrangements hve been made.				
                    {'\n'} 
                    {'\n'} 
                  </Text>

                  <Text style={styles.tableCellHeader2}>
                    Saya setuju untuk menyelesaikan biaya kamar secara keseluruhan apabila saya
                    berangkat meninggalkan hotel/check out lebih awal.
                    {'\n'} 
                    I agree to settle balance room charges in event of early departure.
                  </Text>
                  <Text style={styles.italic}>
                    {'\n'} 
                    Saya setuju bahwa biaya tambahan untuk kehilangan kunci kamar sebesar IDR. 50.000 net
                    {'\n'} 
                    I understand that there will be penalty of IDR 50,000 nett if room keys has been lost 
                    {'\n'} 
                    {'\n'} 
                  </Text>
                </Text>
              </View>
              <View style={styles.tableCol5}>
                <Text style={styles.tableCellHeader2}>
                  Dengan menandatangani formulir ini, saya
                  mengerti dan mematuhi segala ketentuan yang
                  berlaku di HOTEL, termasuk tidak membawa
                  SENJATA, DURIAN, BINATANG PELIHARAAN,
                  NARKOBA dan BARANG LAIN YANG
                  MELANGGAR HUKUM.
                </Text>
                <Text style={styles.italic}>
                  {'\n'} 
                  By Sign this form, I understand and comply with all
                  applicable regulation at the HOTEL, including NOT TO
                  bring FIREARMS, DURIAN, PETS, DRUGS and 
                  ANY OTHER ITEMS WHICH IS PROHIBITED BY
                  THE LAW
                  {'\n'} 
                  {'\n'} 
                </Text>
              </View>
            </View>
          </View>

          {/* Signature Section */}
          <View style={styles.signatureRow}>
            <View style={styles.signatureField}>
              <Text>Tanda Tangan Tamu</Text>
              <Text>Guest Signature</Text>
            </View>
            <View style={styles.signatureField}>
              <Text>Karyawan</Text>
              <Text>Clerk Initial</Text>
            </View>
          </View>

          {/* Footer */}
          <View style={styles.footer}>
            <Text style={styles.footerHeader}>Namroom</Text>
            <Text>Jl, Pinangsia Raya No 1. Jakarta</Text>
            <Text>Phone : +62 89666424263</Text>
            <Text>email : namglodokhotel@gmail.com | Namroom.id</Text>
          </View>
        </Page>
      </Document>
    </>
    )
  };

  const download = (e) => {
    e.preventDefault()
    ReactDOM.render(<MyDocument />, `Desktop/example.pdf`);
  }

  return (    
    <>
      { !isLoading && 
        <Row className="invoiceContainer text-center">
          <PDFViewer className="mt-5">
            <MyDocument datas={datas}/>
            {/* <MyDocument/> */}
          </PDFViewer>
        </Row>
      }
    </>
  );
};

export default InvoicePage;
