import React, { useEffect } from "react";
import Dashboard from '../components/Dashboard/Dashboard'

const DashboardPage = () => {
  useEffect(()=>{
    localStorage.setItem("pagePos","DashboardPage")
  },[])

  return (    
    <div className="container_right_form_0">
      <Dashboard
        pageName={"Reservation List"}
        linkAddNew={"../newShipyardOwner"}
      />
    </div>
  );
};

export default DashboardPage;
