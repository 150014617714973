import React, { useState } from "react";
import { Row, Col, Form  } from 'react-bootstrap'
import styles from './LoginRegister.module.scss'

const ContactUs = () => {

	return (
		<Col className={"col-md-12 mt-5 bg-grey"}>
      <br/>
      <h2>
        Contact Us
      </h2>
      <p className={styles.privPol}>
        Contact Us
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us to:
        <br/>
        <br/>
          Email: info@namroom.co.id
        <br/>
          Phone: (+62) 8191 070 0008.
      </p>
		</Col>
	);
};

export default ContactUs;
