import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { connect } from "react-redux";
import { setCreateProperty } from '../store/actions/propertyAction'
import { setAllHotel } from '../store/actions/hotelAction'

const AddPropertyPage = ({ dispatch, dataProperty, dataHotel }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [hotelName, setHotelName] = useState("");
  const [roomType, setRoomType] = useState("");
  const [roomCapacity, setRoomCapacity] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");
  const [availHotels, setAllAvailHotels] = useState("");
  const [totalRoom, setTotalRoom] = useState("");

  const doCreateProperty = (e) => {
    e.preventDefault()
    const name = localStorage.getItem('hotelId') || selectedHotel.id
    const dataCreate = {
      hotel_id: name,
      type: roomType,
      capacity: roomCapacity,
      rooms: totalRoom,
    }
    setCreateProperty(dispatch, dataCreate)
  }
  
  const handleSelect = (e) => {
    const splitValue = e.target.value.split("||")
    setSelectedHotel({id: splitValue[0], name: splitValue[1]}) 
  }

  useEffect(()=>{
    if( dataProperty.createPropertyResp ){
    }
  },[dataProperty.createPropertyResp])

  useEffect(()=>{
    if( dataHotel.hotelResp ){
      // set id : .. , name: ...
      setAllAvailHotels(dataHotel.hotelResp)
      setSelectedHotel(dataHotel.hotelResp[0])
      setIsLoading(false)
    }
  },[dataHotel.hotelResp])

  useEffect(()=>{
    if( localStorage.getItem('is_super_admin') === 'true' ){
      setAllHotel(dispatch)
    }else{
      setHotelName(localStorage.getItem('hotelName'))
      setIsLoading(false)
    }
  },[])

  const dataForm = [
    {
      label: "Nama Tipe Kamar",
      type: "text",
      placeholder: "Masukkan nama tipe kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomType,
      action: setRoomType,
    },{
      label: "Kapasitas Kamar",
      type: "text",
      placeholder: "Masukkan kapasitas kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomCapacity,
      action: setRoomCapacity,
    },{
      label: "Total Kamar untuk tipe tersebut",
      type: "text",
      placeholder: "Masukkan total kamar untuk tipe tersebut",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: totalRoom,
      action: setTotalRoom,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreateProperty,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../property',
      spaceXs: "3",
    }
  ]

  const dataFormSuperAdmin = [
    {
      label: "Nama Hotel",
      type: "dropdown",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      value: hotelName,
      action: handleSelect,
      required: true,
      notEditable: localStorage.getItem('is_super_admin') === 'true' ? false : true,
      dataDropdown: availHotels,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama Tipe Kamar",
      type: "text",
      placeholder: "Masukkan nama tipe kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomType,
      action: setRoomType,
    },{
      label: "Kapasitas Kamar",
      type: "text",
      placeholder: "Masukkan kapasitas kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomCapacity,
      action: setRoomCapacity,
    },{
      label: "Total Kamar untuk tipe tersebut",
      type: "text",
      placeholder: "Masukkan total kamar untuk tipe tersebut",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: totalRoom,
      action: setTotalRoom,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreateProperty,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../property',
      spaceXs: "3",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Tambah Property (tipe kamar)"}
        dataForm={localStorage.getItem('is_super_admin') === 'true' ? dataFormSuperAdmin : dataForm}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        onSubmit={doCreateProperty}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataProperty: state.property,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(AddPropertyPage)
