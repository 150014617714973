const initialState = {
  hotelResp:null,
}
  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_HOTEL': {
      return {...state, hotelResp: action.payload}
    }
    default:
      return state
  }
}

export default reducer
