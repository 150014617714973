import axios from 'axios';
import { checkResponseMessage } from '../../helper/helper'

export const setLoginResp = async (dispatch, data) => {
  const options = {
    method  : 'post',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    data    : data,
    url     : `${process.env.REACT_APP_API_URI}/auth/login`,
  }

  axios(options).then(({data}) => {
    checkResponseMessage(true, "Login Success", "Please wait..")
    localStorage.setItem("token", `Bearer ${data.access_token}`);
    localStorage.setItem('webHMSLogedIn', true)
    localStorage.setItem("email", data.user.email);
    localStorage.setItem("id", data.user.id);
    localStorage.setItem("is_super_admin", data.user.is_super_admin || "false") ;
    localStorage.setItem("name", data.user.name);
    if(data.user.hotel_id){
      localStorage.setItem("hotelId", data.user.hotel_id);
      localStorage.setItem("hotelName", data.user.name);
    }

    dispatch({ type: 'SET_LOGIN_RESP', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setLogoutResp = async (dispatch) => {
  
  localStorage.clear()
  checkResponseMessage(true, "Logout Success", "Please wait..")
  dispatch({ type: 'SET_LOGOUT_RESP' })

}
