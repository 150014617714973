import React, { useEffect } from "react";
import ReservationTable from '../components/Table/ReservationTable'

const ReservationPage = () => {
  useEffect(()=>{
    localStorage.setItem("pagePos","ReservationPage")
  },[])

  return (    
    <div className="container_right_form">
      <ReservationTable
        pageName={"Reservation List"}
        linkAddNew={"../newShipyardOwner"}
      />
    </div>
  );
};

export default ReservationPage;
