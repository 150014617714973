import axios from 'axios';
import { checkResponseMessage } from '../../helper/helper'

export const setDashboardOverviewPerHotel = async (dispatch, hotel_id) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    url     : `${process.env.REACT_APP_API_URI}/dashboard/overview?hotel_id=${hotel_id}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_DASHBOARD_OVERVIEW_PER_HOTEL', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setDashboardRegionOverview = async (dispatch) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    url     : `${process.env.REACT_APP_API_URI}/dashboard/region_overview`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_DASHBOARD_REGION_OVERVIEW', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setDashboardStatisticPerHotel = async (dispatch, hotel_id) => {
  axios.get(`${process.env.REACT_APP_API_URI}/dashboard/statistic?hotel_id=${hotel_id}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_DASHBOARD_STATISTIC_PER_HOTEL', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}