import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { checkResponseMessage } from "../helper/helper";
import { setOneProperty, setUpdateDetailProperty, setDeletePropertyById } from '../store/actions/propertyAction'

const PropertyDetailPage = ({ dispatch, dataProperty }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { propertyId } = useParams()
  const [hotelId, seHotelId] = useState("");

  const [hotelName, setHotelName] = useState("");
  const [roomType, setRoomType] = useState("");
  const [roomCapacity, setRoomCapacity] = useState("");
  const [totalRoom, setTotalRoom] = useState("");

  const doUpdate = (e) => {
    e.preventDefault()
    const dataUpdate = {
      hotel_id: hotelId,
      type: roomType,
      capacity: roomCapacity,
      rooms: totalRoom,
    }
    setUpdateDetailProperty(dispatch, dataUpdate, propertyId)
  }

  const doDelete = async (e) => {
    e.preventDefault()
    const needConfirmation = await checkResponseMessage(false, "Menghapus property", "Apakah anda yakin untuk menghapus property hotel ini?", true)
    if(needConfirmation){
      setDeletePropertyById(dispatch, propertyId)
    }
  }

  useEffect(()=>{
  },[])
  
  useEffect(()=>{
    if( dataProperty.propertyDetailResp ){
      let data = dataProperty.propertyDetailResp
      seHotelId(data.hotel_id)
      setHotelName(data.hotel_name)
      setRoomType(data.type)
      setRoomCapacity(data.capacity)
      setTotalRoom(data.rooms)
      setIsLoading(false)
    }
  },[dataProperty.propertyDetailResp])

  useEffect(()=>{
    setOneProperty(dispatch, propertyId)
    seHotelId(propertyId)
  },[dispatch, propertyId])

  const dataForm = [
    {
      label: "Nama Hotel (tidak dapat diganti)",
      type: "text",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      notEditable: true,
      required: true,
      value: hotelName,
      action: setHotelName,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Nama Tipe Kamar",
      type: "text",
      placeholder: "Masukkan nama tipe kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomType,
      action: setRoomType,
    },{
      label: "Kapasitas Kamar",
      type: "text",
      placeholder: "Masukkan kapasitas kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomCapacity,
      action: setRoomCapacity,
    },{
      label: "Total Kamar untuk tipe tersebut",
      type: "text",
      placeholder: "Masukkan total kamar untuk tipe tersebut",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: totalRoom,
      action: setTotalRoom,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Update",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../property',
      spaceXs: "3",
    },{
      label: "Hapus",
      type: "buttonDelete",
      spaceMd: "3",
      spaceXs: "3",
      action: doDelete,
    },
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Detail Property Hotel"}
        dataForm={dataForm}
        linkAccReview={"../accountReview"}
        orderId={propertyId}
        pageFor={"detail"}
        onSubmit={doUpdate}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataProperty: state.property,
  };
};

export default connect(
  storage
)(PropertyDetailPage)
